export const UPDATE_LANG = (state, param) => {
    state.setting.language = param
}
export const UPDATE_WINTYPE = (state, param) => {
    state.setting.wintype = param
}
export const UPDATE_SHOWTYPE = (state, param) => {
    state.setting.showtype = param
}
export const BASE_TEAM = (state, param) => {
    state.usualUpdate.teams = param
}
export const UPDATE_TEAM = (state, param) => {
    state.usualUpdate.teams = param
}

export const UPDATE_DEALER = (state, param) => {
    state.usualUpdate.dealer = param
}
export const UPDATE_PREVAILING = (state, param) => {
    state.usualUpdate.prevailing = param
}
export const UPDATE_COMBO = (state, param) => {
    state.usualUpdate.combo = param
}
export const UPDATE_SEATS = (state, param) => {
    state.usualUpdate.seats = param
}


export const BASE_PLAYERS = (state, param) => {
    state.usualUpdate.players = param
}
export const UPDATE_PLAYERS = (state, param) => {
    state.usualUpdate.players.forEach((player) => {
        param.forEach((row) => {
            if (player.playerid === row.player) {
                player.total_all_lose = row.records.all.total_all_lose
                player.total_draw = row.records.all.total_draw
                player.total_games = row.records.all.total_games
                player.total_hands = row.records.all.total_hands
                player.total_points = row.records.all.total_points
                player.total_riichis = row.records.all.total_riichis
                player.total_self_lose = row.records.all.total_self_lose
                player.total_success_riichis = row.records.all.total_success_riichis
                player.total_tsumo = row.records.all.total_tsumo
                player.total_winning = row.records.all.total_winning
            }
        })
    })

    // state.usualUpdate.players = param
}


export const UPDATE_LIVESHOW_STATUS = (state, param) => {
    state.dayUpdate.isLiveShowEnd = param
}
export const UPDATE_LIVESHOW = (state, param) => {
    state.dayUpdate.liveShow = param
}
export const UPDATE_GAME = (state, param) => {
    state.dayUpdate.game = param
}
export const UPDATE_ROUND = (state, param) => {
    state.dayUpdate.round = param
}
