export const setting = state => state.setting

export const apiUrl = (state) => {
  if (state.apiUrl.indexOf('http') === -1) {
    return window.location.protocol + '//' + state.apiUrl
  } else {
    return state.apiUrl
  }

}

