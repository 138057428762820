import axios from 'axios'


const Axios = axios.create({
	config: {
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': 'Content-Type'
		}
	}
});
Axios.interceptors.response.use(response => {
	// const {data} = response.data;
	if (response.data.returnCode > 0) {
		throw response.data.returnCode;
	}
	return response
});

export default {
	getData(getters, {type, credentials}) {
		let url = getters.apiUrl + type + '/get/' + credentials
		return Axios.get(
			url,
		).then(res => {
			return res
		})
	},
	teamDetailBySeason(getters, {credentials}) {
		let url = getters.apiUrl + 'Player/getSeasonTeamsDetail/' + credentials
		return Axios.post(
			url
		).then(res => {
			return res
		})
	},
	registration(getters, {credentials}) {
		const formdata = new FormData()

		formdata.append('seasonId', credentials.seasonId)
		formdata.append('realName', credentials.realName)
		formdata.append('partnerName', credentials.partnerName)
		formdata.append('gender', credentials.gender)
		formdata.append('birthday', credentials.birthday)
		formdata.append('profession', credentials.profession)
		formdata.append('mahjongAge', credentials.mahjongAge)
		formdata.append('experience', credentials.experience)
		formdata.append('ig', credentials.ig)
		formdata.append('fb', credentials.fb)
		formdata.append('yt', credentials.yt)
		formdata.append('phone', credentials.phone)
		formdata.append('line', credentials.line)
		formdata.append('mail', credentials.mail)
		formdata.append('image', credentials.image)
		formdata.append('isOutbound', credentials.isOutbound)
		formdata.append('withPhotogenic', credentials.withPhotogenic)
		formdata.append('haveTransportation', credentials.haveTransportation)
		formdata.append('haveTime', credentials.haveTime)

		let url = getters.apiUrl + 'Registration/create'
		return Axios.post(
			url, formdata
		).then(res => {
			return res
		})
	},
}

