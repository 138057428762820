const setting = require('../utils/config.js');

let initialState = {}

initialState = {
    apiUrl: (process.env.NODE_ENV == 'production') ? setting.prod.apiUrl : setting.stage.apiUrl,
    setting: {
        version: setting.version,
        apiUrl: (process.env.NODE_ENV == 'production') ? setting.prod.apiUrl : setting.stage.apiUrl,
        teamScoreOnly: true,
        wintype: ['a','b','c'],
        playoff:{
            year: setting.playoffYear,
            month: setting.playoffMonth,
            date: setting.playoffDate,
        },
        showtype: 2
    },
    usualUpdate: {
        dealer: 'e',
        prevailing: 'e',
        combo: 0,
        teams: [],
        players: [],
        seats: []
    },
    dayUpdate: {
        isLiveShowEnd: true,
        liveShow: {},
        game: {},
        round: {},
    },

    // liveShow: {
    //     playerE: 1,
    //     playerW: 2,
    //     playerS: 3,
    //     playerN: 4,
    // }
}

export const state = initialState
