import axios from 'axios'
import api from '@/utils/_api'
// import assist from '@/components/assist/index.js'

const Axios = axios.create({
  config: {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type'
    }
  }
});
Axios.interceptors.response.use(response => {
  const {data} = response;
  if (data.err) {
    throw data.err;
  }
});

export const getData = ({getters}, type, credentials) => {
  return api.getData(getters, type, credentials)
    .then((res) => {
      return res.data
    })
}
export const teamDetailBySeason = ({getters}, credentials) => {
  return api.teamDetailBySeason(getters, credentials)
    .then((res) => {
      return res.data
    })
}

export const registration = ({getters}, credentials) => {
  return api.registration(getters, credentials)
      .then((res) => {
        return res.data
      })
}
