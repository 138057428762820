import Vue from 'vue'
import App from './App.vue'
import store from './store'
import eventBus from '@/eventBus'
import DataManager from '@/utils/DataManager'

Vue.config.productionTip = false

Vue.prototype.$dataManager = new DataManager()

import(`@/router/`).then(module => {
	let router = module.default;

	window.vue = new Vue({
		data() {
			return {
				bus: eventBus,
				liveshowid: null,
				teams: [],

			}
		},
		watch: {
			teams(val) {
				this.$bus.$emit('updateTeam', val)
			},
			liveshowid(val) {
				this.$bus.$emit('updateLive', val)
			},
		},
		router,
		store,
		render: h => h(App)
	}).$mount('#app')
})
