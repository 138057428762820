import store from "@/store";

export default class DataManager {
	constructor() {
		this.store = store
		this.getters = store.getters
		this.liveSetting = window.liveSetting
		this.keyDownFunction = {}
		this.isSetKeyDownFunction = false;
	}

	getSeasonData(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('getData', {type: 'Season', credentials: data}).then((res) => {
				if (!res.errorCode) {
					this.store.commit('UPDATE_STATUS', {season: res.data})
					this.store.commit('UPDATE_TEAM', res.data.teams)
					this.store.commit('CLOSE_LOADING')
					this.store.dispatch('winningTypeListByGameType', {credentials: res.data.gtId}).then((res) => {
						if (!res.errorCode) {
							this.store.commit('UPDATE_WINTYPE', res.data)
						}
					})
					resolve(res)
				}
			})
		})
	}

	createLiveShow(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('liveCreate', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					data.liveShow.id = res.data.id
					this.store.commit('UPDATE_STATUS', data)
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	createGame(data) {
		this.store.commit('OPEN_LOADING')
		var liveId = data.liveId
		this.getters.mahjong.usualUpdate.seats.forEach((seat) => {
			switch (seat.position) {
				case 'e':
					data.game.position.e = {
						playerId: seat.playerId,
						x: seat.location.x,
						y: seat.location.y,
					}
					break;
				case 's':
					data.game.position.s = {
						playerId: seat.playerId,
						x: seat.location.x,
						y: seat.location.y,
					}
					break;
				case 'w':
					data.game.position.w = {
						playerId: seat.playerId,
						x: seat.location.x,
						y: seat.location.y,
					}
					break;
				case 'n':
					data.game.position.n = {
						playerId: seat.playerId,
						x: seat.location.x,
						y: seat.location.y,
					}
					break;
			}
		})
		return new Promise((resolve) => {
			this.store.dispatch('gameCreate', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					data.game.id = res.data.id
					this.getters.mahjong.liveUpdate.setNewGame = false
					this.store.commit('UPDATE_STATUS', data)
					this.getTeamByLiveShow(liveId);
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	createRound(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('roundCreate', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					data.round.id = res.data.id
					this.store.commit('UPDATE_STATUS', data)
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	createHand(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('handCreate', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					this.store.commit('CLEAR_SEAT_HAND_POINT')
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	updateHand(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('handUpdate', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					this.store.commit('CLEAR_SEAT_HAND_POINT')
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	cleanLiveShowData() {
		this.getters.mahjong.liveDetail = {}
		this.getters.mahjong.liveUpdate = {
			isLiveShowEnd: true,
			lastChampion: 4,
			setNewGame: false,
			setNewRound: false,
			wintype: [],
			season: {},
			liveShow: {},
			game: {},
			round: {},
		}
		this.getters.mahjong.usualUpdate = {
			dealer: 'e',
			prevailing: 'e',
			riichied: 0,
			teams: [],
			seats: [
				{
					position: 'e',
					setPlayer: false,
					isHost: true,
					combo: 0,
					teamid: null,
					team: null,
					playerId: null,
					player: null,
					point: 0,
					temp_point: 0,
					hand_point: 0,
					riichi: false,
					location: {
						x: 0,
						y: 0
					}
				},
				{
					position: 's',
					setPlayer: false,
					isHost: false,
					combo: 0,
					teamid: null,
					team: null,
					playerId: null,
					player: null,
					point: 0,
					temp_point: 0,
					hand_point: 0,
					riichi: false,
					location: {
						x: 0,
						y: 0
					}
				},
				{
					position: 'w',
					setPlayer: false,
					isHost: false,
					combo: 0,
					teamid: null,
					team: null,
					playerId: null,
					player: null,
					point: 0,
					temp_point: 0,
					hand_point: 0,
					riichi: false,
					location: {
						x: 0,
						y: 0
					}
				},
				{
					position: 'n',
					setPlayer: false,
					isHost: false,
					combo: 0,
					teamid: null,
					team: null,
					playerId: null,
					player: null,
					point: 0,
					temp_point: 0,
					hand_point: 0,
					riichi: false,
					location: {
						x: 0,
						y: 0
					}
				}
			],
		}
	}

	liveShowDetail(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('liveDetail', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					this.store.commit('CLEAR_SEAT_HAND_POINT')
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	playerDetail(data) {
		return new Promise((resolve) => {
			this.store.dispatch('playerDetail', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					resolve(res)
				}
			})
		})
	}


	getLastSeasonChampion(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('getLastSeasonChampion', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					this.store.commit('UPDATE_CHAMPION', res.data.teamId)
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	getTeamBySeason(data) {
		this.store.commit('OPEN_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('getTeamBySeason', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					this.store.commit('UPDATE_SEAT_POINT', res.data)
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	getTeamByLiveShow(data) {
		this.store.commit('CLOSE_LOADING')
		return new Promise((resolve) => {
			this.store.dispatch('getTeamByLiveShow', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					this.store.commit('CLEAR_SEAT_HAND_POINT')
					this.store.commit('UPDATE_SEAT_POINT', res.data)
					this.store.commit('CLOSE_LOADING')
					resolve(res)
				}
			})
		})
	}

	tsumoData(data, dealerId, comboMode, riichied) {
		var handsData = []
		var handPoints = []
		var dealerBase = 0
		var combo = 0
		var riichi = riichied
		var continueCombo = false


		this.getters.mahjong.usualUpdate.seats.forEach((seat) => {
			if (seat.riichi) {
				riichi++
				let hp = {
					result: 4,
					playerId: seat.playerId,
					points: comboMode ? -2 : -1,
					bann: 0,
					winTypes: [],
				}
				handPoints.push(hp)
			}
		});
		this.getters.mahjong.usualUpdate.seats.forEach((seat) => {
			if (seat.isHost) combo = seat.combo

			let ar = {
				playerId: seat.playerId,
				points: 0,
				winTypes: data.winTypes,
				bann: data.bann,
				dealer: 0,
			}
			if (seat.playerId === dealerId) { //dealer

				dealerBase = seat.combo * 2 + 1
				if (seat.playerId === data.playerId) { //win
					continueCombo = true
					let points = (data.points + dealerBase) * 3;
					ar.points = comboMode ? (points + riichi) * 2 : points + riichi;
					let hp = {
						result: 2,
						playerId: seat.playerId,
						points: comboMode ? points * 2 : points,
						bann: ar.bann + dealerBase, // need to check
						winTypes: data.winTypes,
					}
					handPoints.push(hp)
					if (riichi > 0) {
						let _hp = {
							result: 4,
							playerId: seat.playerId,
							points: riichi,
							bann: 0,
							winTypes: [],
						}
						handPoints.push(_hp)
					}
				} else {
					let points = 0 - (data.points + dealerBase);
					ar.points = comboMode ? points * 2 : points;
					if (seat.riichi) ar.points--
					ar.bann = 0
					let hp = {
						result: 2,
						playerId: seat.playerId,
						points: comboMode ? points * 2 : points,
						bann: 0,
						winTypes: data.winTypes,
					}
					handPoints.push(hp)
				}

				handsData.push(ar)
			}

		});
		this.getters.mahjong.usualUpdate.seats.forEach((seat) => {
			let ar = {
				playerId: seat.playerId,
				points: 0,
				winTypes: data.winTypes,
				bann: data.bann,
				dealer: 0,
			}

			if (seat.playerId !== dealerId) {
				if (seat.playerId === data.playerId) { //win
					let points = (data.points * 2) + data.points + dealerBase;
					ar.points = comboMode ? (points + riichi) * 2 : points + riichi;
					let hp = {
						result: 2,
						playerId: seat.playerId,
						points: comboMode ? points * 2 : points,
						bann: ar.bann,
						winTypes: data.winTypes,
					}
					handPoints.push(hp)
					if (riichi) {
						let _hp = {
							result: 4,
							playerId: seat.playerId,
							points: riichi,
							bann: 0,
							winTypes: [],
						}
						handPoints.push(_hp)
					}
				} else {
					if (data.playerId === dealerId) {
						let points = 0 - (data.points + dealerBase);
						ar.points = comboMode ? points * 2 : points;
						if (seat.riichi) ar.points--

						let hp = {
							result: 2,
							playerId: seat.playerId,
							points: comboMode ? points * 2 : points,
							bann: 0,
							winTypes: [],
						}
						handPoints.push(hp)
					} else {
						let points = 0 - data.points;
						ar.points = comboMode ? points * 2 : points;
						if (seat.riichi) ar.points--

						ar.bann = 0

						let hp = {
							result: 2,
							playerId: seat.playerId,
							points: comboMode ? points * 2 : points,
							bann: 0,
							winTypes: [],
						}
						handPoints.push(hp)
					}
				}
				handsData.push(ar)
			}
		})

		return data = {
			combo: combo,
			handPoints: handPoints,
			handsData: handsData,
			continueCombo: continueCombo
		}

	}

	formatDataShow(teams, liveSetting, pointChange, arr, lastArr, isAll = false) {
		var data = []
		teams.forEach((team) => {
			arr.forEach((item, j) => {
				var i = {
					team: {},
					player: {},
				}
				if (team.id === item.teamId) {
					i.teamId = item.teamId
					i.team.name = team.name
					i.team.displayName = team.displayName || team.name
					i.team.color = team.color
					i.team.icon = team.icon
					i.team.point = 0
					team.players.forEach((player) => {
						item.players.forEach((p, k) => {
							if (player.id === p.playerId) {
								i.team.point += p.detail.points
								if (p.nowPlaying || isAll) {
									i.player = p.detail
									i.player.playerId = p.playerId
									i.player.displayName = player.displayName || player.name
									i.x = p.x
									i.y = p.y
									i.changePoint = (pointChange) ? p.detail.points - lastArr[j].players[k].detail.points : 0
									i.riichi = (liveSetting.status.riichis && liveSetting.status.riichis.indexOf(p.playerId) !== -1) || false;
									i.host = (p.position === liveSetting.status.wind);
									data.push(i)
								}
							}
						})
					})
				}
			})
		})
		return data
	}

	positionName(position) {
		var res = ''
		switch (position) {
			case 'e':
				res = '東'
				break;
			case 'n':
				res = '北'
				break;
			case 's':
				res = '南'
				break;
			case 'w':
				res = '西'
				break;
		}
		return res
	}

	pointColor(point) {
		var className = 'neutral'
		if (point > 0) {
			className = 'positive'
		}
		if (point < 0) {
			className = 'negative'
		}

		return className
	}

	findPlayer(playerId) {
		var data = {
			team: {},
			player: {},
		}
		this.getters.mahjong.usualUpdate.teams.forEach((team) => {
			team.players.forEach((player) => {
				if (player.id === playerId) {
					data.team = team
					data.player = player
				}
			})
		})
		return data
	}

	findTeam(teamId) {
		var data = {
			team: {},
			player: {},
		}
		this.getters.mahjong.usualUpdate.teams.forEach((team) => {
			if (team.id === teamId) {
				data.team = team
				data.player = team.players
			}
		})
		return data
	}

	recordLog(data) {
		return new Promise((resolve) => {
			this.store.dispatch('recordLog', {credentials: data}).then((res) => {
				if (!res.errorCode) {
					resolve(res)
				}
			})
		})
	}

	addComboKeyDown(key, cbFn, combo = {ctrl: true, alt: false, shift: true}) { // Default for our system combo key is Alt + Shift
		if (typeof (cbFn) != 'function') {
			throw new Error('addComboKeyPress without a callback function')
		}
		let eventKey = this.comboKeyName(key, combo);
		this.keyDownFunction[eventKey] = cbFn;

		if (!this.isSetKeyDownFunction) {

			window.addEventListener('keydown', (e) => {

				let rKey = String.fromCharCode(e.keyCode);
				let comboIns = {ctrl: e.ctrlKey, alt: e.altKey, shift: e.shiftKey};
				let eventKeyIns = this.comboKeyName(rKey, comboIns);
				if (typeof (this.keyDownFunction[eventKeyIns]) == 'function') {
					this.keyDownFunction[eventKeyIns](rKey, comboIns, e)
					e.preventDefault();
				}
			})
			this.isSetKeyDownFunction = true;
		}
	}

	comboKeyName(key, combo) {
		if (typeof (key) != 'string') {
			throw new Error('addComboKeyPress without a string key')
		}
		key = key.toUpperCase();
		let keyCode = key.charCodeAt(0);
		let keyMap = [];
		if (combo.ctrl) keyMap.push('ctrl');
		if (combo.alt) keyMap.push('alt');
		if (combo.shift) keyMap.push('shift');
		keyMap.push(keyCode.toString());
		return keyMap.join('+');
	}

	removeComboKeyDown(key, combo = {ctrl: true, alt: false, shift: true}) {
		let eventKey = this.comboKeyName(key, combo);
		this.keyDownFunction[eventKey] = undefined;
	}
}
